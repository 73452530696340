import $ from 'jquery';


// import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "./libs/@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('./libs/@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from './libs/@elements/go-to-link';
app.goToLink = goToLink.init;

import * as copyright from './scripts/copyright';
app.modules.copyright = copyright.initInScope;

import * as toc from './libs/@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from './libs/@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as lightbox from './libs/@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as accessibilityNav from './libs/@elements/accessibility-nav';
app.modules.accessibilityNav = accessibilityNav.createInitInScope('.js-touch-nav', '.js-touch-nav__item');

import * as touchNav from './libs/@elements/touch-nav';
app.modules.touchNav = touchNav.initInScope;

import * as toggleNav from './scripts/toggle-nav';
app.modules.toggleNav = toggleNav.initInScope;

import * as affixNavbar from './scripts/affix-navbar';
app.affixNavbar = affixNavbar.init;

import * as actionChanger from './libs/@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as datepicker from './libs/@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerRange from './scripts/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as slider from './libs/@elements/slider';

app.modules.imgSlider = slider.createInitInScope('.js-slider', {
    slidesToShow: 1,
    swipeToSlide: true,
    infinite: false,
    slidesToScroll: 1,
    dotsClass: matchMedia('(max-width: 767px)').matches ? 'sr-only' : 'slick-dots',
    prevArrow: '<button type="button" aria-label="previous" class="btn-no-styling slick-arrow slick-prev"><span class="icon icon-chevron icon-rotate-90"></span></button>',
    nextArrow: '<button type="button" aria-label="next" class="btn-no-styling slick-arrow slick-next"><span class="icon icon-chevron icon-rotate-270"></span></button>'
});

app.modules.teaserSlider = slider.createInitInScope('.js-teaser-slider', {
    slidesToShow: matchMedia('(max-width: 767px)').matches ? 1 : 2,
    swipeToSlide: true,
    infinite: false,
    slidesToScroll: 1,
    dotsClass: matchMedia('(max-width: 767px)').matches ? 'slick-dots slick-dots--grey slick-dots--relative' : 'sr-only',
    prevArrow: '<button type="button" aria-label="previous" class="btn-no-styling slick-arrow slick-arrow--lg slick-prev d-none d-md-block"><span class="icon icon-chevron icon-rotate-90"></span></button>',
    nextArrow: '<button type="button" aria-label="next" class="btn-no-styling slick-arrow slick-arrow--lg slick-next d-none d-md-block"><span class="icon icon-chevron icon-rotate-270"></span></button>'
});

app.modules.reviewSlider = slider.createInitInScope('.js-review-slider', {
    slidesToShow: 1,
    swipeToSlide: true,
    infinite: false,
    slidesToScroll: 1,
    dotsClass: matchMedia('(max-width: 767px)').matches ? 'slick-dots slick-dots--grey slick-dots--relative' : 'sr-only',
    prevArrow: '<button type="button" aria-label="previous" class="btn-no-styling slick-arrow slick-arrow--plain slick-prev d-none d-md-block"><span class="icon icon-chevron icon-rotate-90"></span></button>',
    nextArrow: '<button type="button" aria-label="next" class="btn-no-styling slick-arrow slick-arrow--plain slick-next d-none d-md-block"><span class="icon icon-chevron icon-rotate-270"></span></button>'
});

app.modules.brandsSlider = slider.createInitInScope('.js-brands-slider', {
    slidesToShow: matchMedia('(max-width: 767px)').matches ? 3 : 6,
    swipeToSlide: true,
    infinite: false,
    slidesToScroll: 1,
    dotsClass: 'sr-only',
    prevArrow: '<button type="button" aria-label="previous" class="btn-no-styling slick-arrow slick-arrow--plain slick-prev"><span class="icon icon-chevron icon-rotate-90"></span></button>',
    nextArrow: '<button type="button" aria-label="next" class="btn-no-styling slick-arrow slick-arrow--plain slick-next"><span class="icon icon-chevron icon-rotate-270"></span></button>'
});

import * as dataTableSlider from './scripts/datatable-slider';
app.modules.dataTableSlider = dataTableSlider.initInScope;

import * as ajaxForm from './libs/@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({addUrlParams: true});

import * as parsley from './libs/@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as matchmediaViewer from "./scripts/matchmedia-viewer";
app.matchmediaViewer = matchmediaViewer.init;
app.modules.matchmediaViewer = matchmediaViewer.initInScope;

import * as smoothScroll from './scripts/smooth-scroll';
app.modules.smoothScroll = smoothScroll.initInScope;

import * as map from './scripts/map';
app.modules.map = map.initInScope;

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);