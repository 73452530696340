"use strict";

let $navbar,
    $navbarContainer,
    navbarHeight,
    offset,
    didScroll,
    defaultTopPosition;

function scrollHandler() {
    if($(window).scrollTop() > offset) {
        $navbar.addClass('is-affix');
    } else if($(window).scrollTop() <= offset) {
        $navbar.removeClass('is-affix');
    }
}

export function init() {
    $navbar = $('.js-affix-navbar');
    $navbarContainer = $('.js-affix-navbar__container');
    defaultTopPosition = $navbarContainer.offset().top;

    offset = $navbarContainer.offset().top;

    if($(window).scrollTop() > offset) {
        $navbar.addClass('is-affix');
    }

    $(window).one('scroll', function () {
        navbarHeight = $navbar[0].getBoundingClientRect().height; /*get exact height (jquery returns rounded value)*/
        $navbarContainer.height(navbarHeight);
    });

    $(window).scroll(function () {
        didScroll = true;
    });

    requestAnimationFrame(function animationFrameHandler() {
        if (didScroll) {
            scrollHandler();

            didScroll = false;
        }
        requestAnimationFrame(animationFrameHandler);
    });
}