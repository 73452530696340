"use strict";

import $ from 'jquery'
import { getConfig } from '../config-utils'

export default function ($scope) {
    let _config = getConfig('_config');

    if(!$scope) {
        $scope = $('body');
    }

    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( _config['_preload'][_key] && app.modules[_key] && typeof window.app.modules[_key] === 'function' ){
                window.app.modules[_key]($scope);
            }
        });
    }

    //init all modules if the right _config key is set for them
    for (let module in _config) {
        if (_config[module] && app.modules[module] && typeof  app.modules[module] === 'function') {
            app.modules[module]($scope);
        }
    }

    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( _config['_reload'][_key] && app.modules[_key] && typeof window.app.modules[_key] === 'function' ){
                window.app.modules[_key]($scope);
            }
        });
    }
}