"use strict";

export function initInScope ($scope) {
    if (matchMedia('(min-width: 768px)').matches) {
        return;
    }

    let $slider = $scope.find('.js-datatable-slider');

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-datatable-slider__main');

            $mainSlider.on('init', function(event, slick){
                $slider.find('.js-datatable-slider__nav__count').text(((slick.currentSlide + 1)));
                $slider.find('.js-datatable-slider__nav__count-sum').text((slick.slideCount));
            });

            $mainSlider.slick({
                slidesToShow: 1,
                dotsClass: 'sr-only',
                infinite: true,
                appendArrows: $container.find('.js-datatable-slider__nav'),
                arrows: true,
                prevArrow: '<button type="button" aria-label="previous" class="btn-no-styling slick-arrow slick-arrow--plain slick-prev"><span class="icon icon-chevron icon-rotate-90"></span></button>',
                nextArrow: '<button type="button" aria-label="next" class="btn-no-styling slick-arrow slick-arrow--plain slick-next"><span class="icon icon-chevron icon-rotate-270"></span></button>'
            });


            $mainSlider.on('afterChange', function(event, slick){
                $slider.find('.js-datatable-slider__nav__count').text(((slick.currentSlide + 1)));
            });
        });
    });
}