"use strict";

export function initInScope ($scope) {
    if (matchMedia('(max-width: 767px)').matches) {
        $scope.find('.js-toggle-nav').on('click', function (evt) {
            evt.preventDefault();

            let $mainNav = $(this).closest('.navbar');

            if(!$mainNav.hasClass('is-open')) {
                $mainNav.addClass('is-open');
            } else {
                $mainNav.removeClass('is-open');
                $mainNav.find('li.is-open').removeClass('is-open');
            }
        });

        $('.js-toggle-nav-level').on('click', function (evt) {
            evt.preventDefault();
            let navItem = $(this).closest('li');

            if(!navItem.hasClass('is-open')) {
                navItem.addClass('is-open');
            } else {
                navItem.removeClass('is-open');
            }
        });
    }
}