"use strict";

import $ from 'jquery';
import loadJQueryUi from '../libs/@elements/load-jquery-ui';
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "../libs/@elements/date-utils";
import {getPrefixedDataSet} from "../libs/@elements/data-set-utils";

const defaultSelectors = {
    base: '.js-datepicker',
    input: '.js-datepicker__input',
    altField: '.js-datepicker__alt-field'
};

const defaultOptions = {
    selectors: defaultSelectors
};

// returns UTC DATE
export function getDate ($datepicker, options = defaultOptions) {
    options = mergeOptions(defaultOptions, options);

    return ISOStringToDate($datepicker.find(options.selectors.altField).val());
}

// date: UTC DATE
export function setDate($datepicker, date, options = defaultOptions) {
    options = mergeOptions(defaultOptions, options);

    loadJQueryUi().then(function () {
        $datepicker.find(options.selectors.altField).val(dateToISOString(date));
        $datepicker.find(options.selectors.input).datepicker('setDate', UTCDateToLocalDate(date));
    });

}

// date: UTC DATE
export function setMinDate($datepicker, date, options = defaultOptions) {
    options = mergeOptions(defaultOptions, options = defaultOptions);

    loadJQueryUi().then(function () {
        if ($datepicker.find('.js-datepicker__input').datepicker('getDate') && $datepicker.find('.js-datepicker__input').datepicker('getDate').getTime() < date.getTime()) {
            $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
            $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
        }

        $datepicker.find(options.selectors.input)
            .datepicker('option', 'minDate', UTCDateToLocalDate(date));
    }).catch(console.error);
}

// date: UTC DATE
export function setMaxDate($datepicker, date, options = defaultOptions) {
    options = mergeOptions(defaultOptions, options);

    loadJQueryUi().then(function () {
        if ($datepicker.find('.js-datepicker__input').datepicker('getDate') && $datepicker.find('.js-datepicker__input').datepicker('getDate').getTime() > date.getTime()) {
            $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
            $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
        }

        $datepicker.find(options.selectors.input)
            .datepicker('option', 'maxDate', UTCDateToLocalDate(date));
    }).catch(console.error);
}

export function setUIOptions($datepicker, options = defaultOptions) {
    options = mergeOptions(defaultOptions, options);

    loadJQueryUi().then(function () {
        $datepicker.find(options.selectors.input)
            .datepicker('option', options);
    });
}

// refresh the currently open datepicker
export function refreshCurrent() {
    loadJQueryUi().then(function () {
        if ($.datepicker._curInst) {
            $.datepicker._curInst.input.datepicker('refresh');
        }
    });
}

export function createInitInScope(options = defaultOptions) {
    options = mergeOptions(defaultOptions, options);
    let {selectors} = options;

    return function initInScope($scope) {
        let $datepickerContainers = $scope.find(selectors.base);

        loadJQueryUi().then(function () {
            $datepickerContainers.each(function () {
                let $container = $(this);
                let $datepicker = $container.find(selectors.input);
                let $altField = $container.find(selectors.altField);

                let options = {
                    numberOfMonths: 1,
                    minDate: UTCDateToLocalDate(new Date()),
                    nextText: '',
                    prevText: '',
                    firstDay: 1,
                    showAnim: 'show', // other animations (like fadeIn) do not work with jquery.slim
                    onSelect: function (dateString, inst) {
                        let selectedDate = new Date(Date.UTC(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
                        $altField.val(dateToISOString(selectedDate)).trigger('change');
                    },
                    ...options,
                    ...transformDataOptions(getPrefixedDataSet('datepicker', $container))
                };

                addKeyboardSupport($datepicker, $altField, options);
                $datepicker.datepicker(options);

                if ($altField.val()) {
                    setDate($container, ISOStringToDate($altField.val()), options);
                }
            });
        });

        return $datepickerContainers;
    };
}

export const initInScope = createInitInScope(defaultOptions);

// private functions
function transformDataOptions(options = defaultOptions) {
    options = {...options};

    if (options.minDate && typeof options.minDate === 'string') {
        options.minDate = UTCDateToLocalDate(ISOStringToDate(options.minDate));
    }

    if (options.maxDate && typeof options.maxDate === 'string') {
        options.maxDate = UTCDateToLocalDate(ISOStringToDate(options.maxDate));
    }

    return options;
}

function addKeyboardSupport($datepicker, $altField) {
    let newDate = localDateToUTCDate($datepicker.datepicker('getDate'));

    $datepicker.on('keydown', function (evt) {
        if(newDate == null) {
            $datepicker.datepicker('setDate', new Date());
            newDate = $datepicker.datepicker('getDate');
        }

        switch (evt.key) {
            case 'ArrowLeft':
                newDate.setDate(newDate.getDate() - 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowUp':
                newDate.setDate(newDate.getDate() - 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowRight':
                newDate.setDate(newDate.getDate() + 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowDown':
                newDate.setDate(newDate.getDate() + 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageUp':
                newDate.setDate(newDate.getDate() - 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageDown':
                newDate.setDate(newDate.getDate() + 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'Enter':
                newDate.setDate(newDate.getDate());
                $datepicker.datepicker('setDate', newDate);
                $altField.val(dateToISOString(localDateToUTCDate(newDate))).trigger('change');
                $datepicker.datepicker('hide');

                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
        }

        if (newDate.getTime() !== $datepicker.datepicker('getDate').getTime()) {
            $datepicker.datepicker('setDate', newDate);
        }
    });
}

function mergeOptions(defaultOptions, options) {
    return {
        ...defaultOptions,
        ...options,
        selectors: {
            ...defaultOptions.selectors,
            ...options.selectors
        }
    }
}

