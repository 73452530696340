"use strict";
export function initInScope($scope) {
    if ($('.js-copyright').length) {
        let copyrightItem = $scope.find('.js-copyright');
        copyrightItem.on('click', function (evt) {
            evt.stopImmediatePropagation();
            evt.preventDefault();
            $(this).toggleClass('active');
        });
    }
}