import $ from 'jquery'
import { getDate, refreshCurrent, setDate, setMaxDate, setMinDate, setUIOptions } from './datepicker'
import { dateToISOString, localDateToUTCDate } from '../libs/@elements/date-utils'
import { translate } from '../libs/@elements/translations'

const HOUR_IN_MS = 60 * 60 * 1000;
const DAY_IN_MS = 24 * HOUR_IN_MS;
const MIN_DURATION_DAYS = 1;
const MIN_DURATION = MIN_DURATION_DAYS * DAY_IN_MS;

export function initInScope($scope) {
    return $scope.find('.js-datepicker-range').each(function () {
        let $from = $(this).find('.js-datepicker-range__from');
        let $to = $(this).find('.js-datepicker-range__to');
        let $input = $(this).find('.js-datepicker__input');

        let newMinDate = false;
        let newMaxDate = false;

        let hasAvailabilities = true;
        let url = $(this).data('datepicker-range-availabilities-url');
        let datepickerSeason = $(this).data('datepicker-season');

        let from = getDate($from);
        let to = getDate($to);

        let loadedFrom = null;
        let loadedTo = null;
        let availabilities = {};

        /*if (from) {
            setMinDate($to, new Date(from.getTime() + MIN_DURATION));
        }*/

        $from.on('change', function () {
            from = getDate($from);

            if (hasAvailabilities) {
                if (to) {
                    //to = roundDay(new Date(from.getTime() + MIN_DURATION));
                    to = roundDay(new Date(from.getTime()));
                    setDate($to, to);
                 }
            } else {
                setMinDate($to, roundDay(new Date(from.getTime() + MIN_DURATION)));
            }
        });

        //onAvailabilityChange();
        function onAvailabilityChange () {
            if (hasAvailabilities) {
                if (from) {
                    loadMonthIfNeeded({year: from.getUTCFullYear(), month: from.getUTCMonth()});
                }

                setUIOptions($from, {
                    onChangeMonthYear: function (year, month) {
                        loadMonthIfNeeded({year, month});
                    },
                    beforeShowDay: function (date) {
                        date = localDateToUTCDate(date);

                        // not available
                        if (availabilities[dateToISOString(roundDay(date))] === false) {
                            return [false, 'is-closed', translate('datepicker.closed')];
                        } else {
                            return [true, 'is-opened', translate('datepicker.opened')];
                        }

                        return getDatepickerCellSettings({date, from, to, availabilities, isArrival: true});

                    }
                });

                setUIOptions($to, {
                    onChangeMonthYear: function (year, month) {
                        loadMonthIfNeeded({year, month});
                    },
                    beforeShowDay: function (date) {
                        date = localDateToUTCDate(date);

                        // not available
                        if (availabilities[dateToISOString(roundDay(date))] === false) {
                            return [false, 'is-closed', translate('datepicker.closed')];
                        } else {
                            return [true, 'is-opened', translate('datepicker.opened')];
                        }

                        return getDatepickerCellSettings();
                    }
                });
            } else {

                setUIOptions($from, {
                    beforeShowDay: function (date) {
                        date = localDateToUTCDate(date);

                        return getDatepickerCellSettings();
                    }
                });

                setUIOptions($to, {
                    beforeShowDay: function (date) {
                        date = localDateToUTCDate(date);

                        return getDatepickerCellSettings();
                    }
                });
            }
        }

        function loadMonthIfNeeded({year, month}) {
            let startOfNewMonth = new Date(Date.UTC(year, month));
            let endOfNewMonth = getLastOfMonth(startOfNewMonth);

            if (!loadedTo || !loadedFrom) {
                let loadDate = new Date();
                loadDate.setDate(loadDate.getDate() + 500);
                loadAvailabilities(
                    getFirstOfMonth(roundDay(new Date(startOfNewMonth.getTime()))),
                    (roundDay(new Date(loadDate.getTime())))
                );
            }/* else if (endOfNewMonth.getTime() + MIN_DURATION > loadedTo.getTime()) {
                loadAvailabilities(loadedTo, getLastOfMonth(new Date(endOfNewMonth.getTime() + MIN_DURATION * 5)));
            } else if (startOfNewMonth.getTime() - MIN_DURATION < loadedFrom.getTime()) {
                loadAvailabilities(getFirstOfMonth(new Date(startOfNewMonth.getTime() - MIN_DURATION * 5)), loadedFrom);
            }*/
        }

        function loadAvailabilities(from, to) {
            let loadingFrom, loadingTo;

            if (!loadedFrom
                || !loadedTo
                || from.getTime() < loadedFrom.getTime() && to.getTime() > loadedTo.getTime()) {
                loadingFrom = from;
                loadingTo = to;
            } else {
                if (from.getTime() < loadedFrom.getTime()) {
                    loadingFrom = from;
                    loadingTo = loadedFrom;
                } else if (to.getTime() > loadedTo.getTime()) {
                    loadingFrom = loadedTo;
                    loadingTo = to;
                }
            }

            if (loadingTo && loadingFrom) {
                $.ajax(url, {
                    data: {
                        from: dateToISOString(loadingFrom),
                        to: dateToISOString(loadingTo),
                        season: datepickerSeason
                    },
                    dataType: 'JSON'
                }).then(response => {
                    if (response && response.success) {
                        if (response.closedDates) {
                            response.closedDates.forEach(dateString => {
                                availabilities[dateString] = false;
                            });
                        }
                        if (response.start) {
                            newMinDate = (new Date(response.start)).getTime();
                            setMinDate($from, roundDay(new Date(newMinDate)));
                            setMinDate($to, roundDay(new Date(newMinDate)));
                        }
                        if (response.end) {
                            newMaxDate = (new Date(response.end)).getTime();
                            setMaxDate($from, roundDay(new Date(newMaxDate)));
                            setMaxDate($to, roundDay(new Date(newMaxDate)));
                        }

                        loadedFrom = loadedFrom
                            ? new Date(Math.min(loadingFrom.getTime(), loadedFrom.getTime()))
                            : loadingFrom;
                        loadedTo = loadedTo
                            ? new Date(Math.max(loadingTo.getTime(), loadedTo.getTime()))
                            : loadingTo;

                        refreshCurrent();

                        // check if datepicker is currently open
                        // if it is open load the current month if needed
                        if ($.datepicker
                            && $.datepicker._curInst
                            && $.datepicker._curInst.input
                            && (
                                $.datepicker._curInst.input.closest($from).length
                                || $.datepicker._curInst.input.closest($to).length
                            )
                        ) {
                            loadMonthIfNeeded({
                                year: $.datepicker._curInst.drawYear,
                                month: $.datepicker._curInst.drawMonth
                            });
                        }
                    }

                }).catch((...params) => {
                    console.error(...params);
                });
            }
        }


        if (_config.loadAvailabilitiesOnPageLoad) {
            let $availabilitiesSelect = $scope.find('.js-availabilities-select');
            let $datepickerInput = $scope.find('.js-datepicker__input')

            $datepickerInput.attr('disabled',false);

            let json = $availabilitiesSelect.find('option:selected').attr('data-availabilities');
            hasAvailabilities = true;
            url = json;

            loadedFrom = null;
            loadedTo = null;
            availabilities = {};

            onAvailabilityChange();
        }


        $scope.find('.js-availabilities-select').on('change',function () {
            $input.attr('disabled',false);

            let json = $(this).find('option:selected').attr('data-availabilities');
            hasAvailabilities = true;
            url = json;

            loadedFrom = null;
            loadedTo = null;
            availabilities = {};

            onAvailabilityChange();
        });


    });
}

function getFirstOfMonth(date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()))
}

function getLastOfMonth(date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1) -  DAY_IN_MS)
}

function roundDay(date) {
    date = new Date(date.getTime() + HOUR_IN_MS);
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
}

function getDatepickerCellSettings() {
    let cssClasses = [];
    let texts = [];
    let isAllowed = true;
    return [isAllowed, cssClasses.join(' '), texts.join(', ')];
}