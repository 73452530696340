import initModulesInScope from "../init-modules-in-scope";
import {clearAll, showNotification} from "../alert-notification";
import $ from "jquery";
import {throwWarning} from "../throw-error";

export default function asyncAppend({
                                        $target,
                                        $loading = $(),
                                        $notifications = $()
                                    }, request) {

    // Unpack json response body if the promise was created via fetch
    request = request.then(response => (response
        && response.json
        && typeof response.json === 'function'
        && response.clone
        && typeof response.clone === 'function')
        ? response.clone().json()
        : response
    );

    //special instanceof check for multiple jQuery functions because of webpack
    let $allTargets = ($target instanceof $ || typeof $target.toArray === 'function')
        ? $target
        : $([].concat(...Object.entries($target).map(([key, $element]) => $element.toArray())));

    //special instanceof check for multiple jQuery functions because of webpack
    let $targetsByResultId = ($target instanceof $ || typeof $target.toArray === 'function')
        ? {default: $target}
        : $target;

    $allTargets.attr('hidden', null);
    $loading.attr('hidden', null);

    if (hasElements($notifications)) {
        clearAll({
            $container: $notifications
        });
    }

    // always show notification even if no specific notification container is provided
    showNotification(request, {
        $container: $notifications
    });

    request.catch((error, requestState) => {
        $loading.attr('hidden', 'hidden');
    });

    return request
        .then(
                // Unpack json response body if the promise was created via fetch
            response => (response
                && response.json
                && typeof response.json === 'function'
                && response.clone
                && typeof response.clone === 'function')
                ? response.clone().json()
                : response
        )
        .then(function (result) {
            let content = result.html || result.content;

            if (content && result.success !== false) {
                let contentByResultId = typeof content === 'string'
                    ? {default: content}
                    : content;
                $allTargets.empty();
                Object.entries(contentByResultId).forEach(([resultId, result]) => {
                    if ($targetsByResultId[resultId]) {
                        $targetsByResultId[resultId].append (result);
                        initModulesInScope($targetsByResultId[resultId]);
                    } else {
                        throwWarning(`../async-append: Response contained entry with unmatched element.
                        Unmatched entry key was "${resultId}".
                        Dom elements were provided for "${Object.keys($targetsByResultId).join(', ')}" `)
                    }
                });
            }

            $loading.attr('hidden', 'hidden');
            return result;
        });
}


function hasElements($element) {
    return $element && $element.length;
}