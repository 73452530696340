"use strict";

import {onEnterViewPort} from "../libs/@elements/viewport-utils";
import {
    default as initMap,
    setMarker,
    loadGoogleMapsAPI,
    showInfoBoxByMarker,
    closeInfoBoxByMarker,
    activateMarker
} from "../libs/@elements/google-map";

export function initInScope($scope) {
    let $maps  = $scope.find('.js-google-map');

    if ($maps && $maps.length) {

        $maps.each(function (index, element) {
            let $container = $(element);
            let $mapCanvas = $container.find('.js-google-map__canvas');

            onEnterViewPort($(element), function () {
                loadGoogleMapsAPI().then(function () {
                    const poiStyles = {
                        default: {
                            default: {
                                url: '/static/build/img/map/poi-blue.svg',
                                size: new google.maps.Size(30, 40),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(15, 40)
                            },
                            active: {
                                url: '/static/build/img/map/poi-red.svg',
                                size: new google.maps.Size(30, 40),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(15, 40)
                            }
                        }
                    };

                    const infoBoxOptions = {
                        pixelOffset: new google.maps.Size(-138, -50),
                        boxStyle: {width: "276px", background: "#fff", padding: "20px"},
                        boxClass: "infobox",
                        closeBoxURL: "/static/img/map/close.svg"
                    };


                    let options = $container.data('google-map-options');
                    options = {...options,
                        gestureHandling: "cooperative",
                        zoomControl: true,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        mapTypeControl: false
                    };
                    let poiVarName = $container.data('google-map-pois');
                    let pois = [];

                    if (poiVarName) {
                        if (!window['_googleMapPois']) {
                            console.error('_googleMapPois is not set');
                        } else if (!window._googleMapPois[poiVarName]) {
                            console.error(poiVarName + ' is not set in _googleMapPois', _googleMapPois);
                        } else {
                            pois = _googleMapPois[poiVarName];
                        }
                    }

                    let mapObj = initMap({
                        element: $mapCanvas[0],
                        mapOptions: options,
                        poiStyles,
                        infoBoxOptions,
                        onActivateMarker,
                        onDeactivateMarker
                    });

                    setMarker(mapObj, pois);

                    if (matchMedia('(min-width: 768px)').matches) {
                        let initiallyOpenedId = $container.data('google-map-initally-opened-id');
                        if (typeof initiallyOpenedId !== "undefined") {
                            mapObj.marker.map(marker => {
                                if (marker.dataId == initiallyOpenedId) {
                                    activateMarker(marker, mapObj);
                                }
                            })
                        }
                    }

                }).catch(function (a,b,c) {
                    console.error(a,b,c);
                });
            }, {
                offset: window.innerHeight * 1.5
            })
        });
    }
}

function onActivateMarker(marker, mapObj) {
    if(marker.detailInfoBoxUrl) {
        let infoBoxPromise = showInfoBoxByMarker(
            marker,
            `<div class="my-2">
                <div class="loading-spinner" aria-label="Loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>
            </div>`,
            mapObj
        );

        let contentPromise = $.ajax(marker.detailInfoBoxUrl, {
            cache: true,
            dataType: 'html',
            headers: {Ajax: 1}
        }).catch(function (a, b, c) {
            console.error(a, b, c);
        });

        $.when(infoBoxPromise, contentPromise).then(function (infoBox, [infoBoxContent]) {
            if(infoBox.getVisible()) {
                // open the new info box with the new content only if the old one is still open
                infoBox.setContent(infoBoxContent);
            }
        });

    }
}

function onDeactivateMarker(marker, mapObj) {
    closeInfoBoxByMarker(marker, mapObj);
}
